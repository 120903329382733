.contentWrapper {
  display: flex;
  justify-content: center;
}

.content {
  padding: 35px;
  display: flex;
  flex-direction: column;
  min-width: 800px;
}

.series-info-content {
  background-color: #222;
  padding: 20px;
  border-radius: 5px;
  max-width: 1000px;
}

.series-help {
  font-size: x-large;
}

.homepageWrapper {
  margin-top: 15px;
}

.pageHeader {
  margin-bottom: 15px;
  font-size: 36px;
  width: max-content;
  padding-left: 15px;
  padding-right: 15px;
}

.clear-btn {
  margin-right: 5px;
}
