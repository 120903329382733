.nav {
  height: 75px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(34, 34, 34, 0.5);
}

.leftNav {
  align-items: baseline;
}

.navLink {
  text-decoration: none;
  margin-left: 15px;
  color: #fff;
}

.subSection:hover {
  text-decoration: underline;
  color: #fff;
}

.siteTitle {
  font-size: 22px;
  color: #fff;
  font-size: 30px;
}

.icon {
  width: 40px;
  height: 40px;
  background-image: linear-gradient(to right, #0051d4 0%, #3d7adb 100%);
  border-radius: 50px;;
}
