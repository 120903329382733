.series {
  background-color: #222;
  padding: 15px;
  margin-top: 15px;
  border-radius: 5px;
}

.series-info {
  display: flex;
  justify-content: space-between;
}

.series-progress:hover {
  cursor: pointer;
}

.series-title {
  font-weight: bold;
  font-size: 25px;
}

.series-progress {
  min-width: 145px;
  text-align: center;
  font-size: large;
  padding: 10px;
  border: 2px solid #fff;
  border-radius: 5px;
  height: min-content;
}

.series-completed {
  background-color: #008000;
}

.series-partial {
  background-color: #013a01;
}

.no-select {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.series-details {
  margin-top: 15px;
}

.series-games {
  width: calc(100% - 20px);
  margin-left: 10px;
  margin-right: 10px;
  min-height: 50px;
}

.status-icon {
  padding-top: 5px;
}

ul {
  margin: 0;
}

td,
th {
  text-align: center;
  vertical-align: middle;
  border-top: 1px solid #fff;
  border-left: 1px solid #fff;
}

td {
  padding: 5px;
}

th {
  font-size: larger;
  padding: 10px;
}

.completed {
  width: 100px;
}

.otherVersions {
  width: 225px;
}

table {
  margin-top: 15px;
  width: 100%;
  height: 100%;
  border-right: 1px solid #fff;
  border-bottom: 1px solid #fff;
  border-radius: 5px;
}
