.series-list {
  margin-top: 15px;
}

.series-header {
  font-size: xx-large;
  font-weight: bolder;
}

.series-completed-total {
  background-color: #222;
  width: max-content;
  padding: 10px;
  margin-top: 10px;
  margin-bottom: 15px;
  border-radius: 5px;
  font-size: larger;
  font-weight: bold;
}
